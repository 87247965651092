const baseUrl = process.env.REACT_APP_API_ENDPOINT;
const defaultHeaders = new Headers();
defaultHeaders.append('Content-Type', 'application/json');

const setHeaders = () => {
    return defaultHeaders;
}

export const request = async (method, url, params) => {
    let headers = setHeaders();

    if (method === 'GET' && params) {
        url += `?${new URLSearchParams(params).toString()}`
    }

    const body = JSON.stringify(params);
    const response = (await fetch(baseUrl + url, {headers, method, body: method === 'GET' ? null : body}));

    const data = await response.json();

    if (!response.ok) {
        const errorMessage = data.message;
        throw new Error(errorMessage || 'Something went wrong.');
    }

    return data;
};

export const get = async (url, params) => request('GET', url, params);
export const post = async (url, params) => request('POST', url, params);
export const put = async (url, params) => request('PUT', url, params);
export const remove = async (url, params) => request('DELETE', url, params);
export const patch = async (url, params) => request('PATCH', url, params);