import React from 'react'
import {compose, withHandlers, withState} from 'recompose'
import {withLayout} from '../../../components/layout'
import {calculateDelivery} from '../../../helpers/car-delivery.helper'
import {ENGINE_TYPE, VEHICLE_TYPE} from '../../../helpers/taxes.helper'
import {ContactUsModal} from '../../../components/contact-us-modal'
import {CallbackModal} from "../../../components/callback-modal";

const now = new Date().getUTCFullYear()
const years = Array(now - (now - 25)).fill('').map((v, idx) => now - idx)

const CalculatorComponent = ({
                                 handleChange,
                                 params,
                                 handleCalculate,
                                 results,
                                 showContactUsModal,
                                 setShowContactUsModal,
                                 showCallbackModal,
                                 setShowCallbackModal,
                             }) =>
    <>
        <ContactUsModal show={showContactUsModal}
                        onClose={() => setShowContactUsModal(false)}/>
        <CallbackModal show={showCallbackModal}
                       onClose={() => setShowCallbackModal(false)}/>
        <div id="page_caption" className="hasbg withtopbar"
             style={{backgroundImage: 'url(upload/custom/calc3.jpg)', height: '40vh', marginTop: 40}}>
            <div className="page_title_wrapper">
                <div className="page_title_inner">
                    <h1 className="withtopbar">Калькулятор доставки авто</h1>
                </div>

            </div>
        </div>
        <div id="page_content_wrapper" className="hasbg withtopbar">
            <div className="sidebar_content calculator_info">
                <div className="calculator_input_block">
                    <h4>Параметри</h4>
                    <div className="car_info_table" style={{paddingTop: 5}}>
                        <label> Повна вартість лоту ($)
                            <br/>
                            <div
                                className="wpcf7-form-control-wrap calculator_number_input_wrapper">
                                <input type="number"
                                       min="0"
                                       name="price"
                                       value={params.price || ''}
                                       onChange={handleChange}
                                       className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                       aria-required="true"
                                       aria-invalid="false"/>
                            </div>
                        </label>
                        <label> Тип транспортного засобу
                            <br/>
                            <span className="wpcf7-form-control-wrap">
                                    <div
                                        className="themeborder calculator_select_input_wrapper">
                                        <select name="type"
                                                onChange={handleChange}>
                                            <option value="auto">Авто</option>
                                            <option value="moto">Мото</option>
                                        </select>
                                        <span className="ti-angle-down"/>
                                    </div>
                                </span>
                        </label>
                        <label> Рік
                            <br/>
                            <span className="wpcf7-form-control-wrap">
                                    <div
                                        className="themeborder calculator_select_input_wrapper">
                                        <select name="year"
                                                onChange={handleChange}>
                                                      {years.map(year => <option
                                                          key={year}
                                                          value={year}>{year}</option>)}
                                        </select>
                                        <span className="ti-angle-down"/>
                                    </div>
                                </span>
                        </label>
                        <label> Тип дивгуна
                            <br/>
                            <span className="wpcf7-form-control-wrap">
                                    <div
                                        className="themeborder calculator_select_input_wrapper">
                                        <select name="engine_type"
                                                onChange={handleChange}>
                                            <option
                                                value="petrol">Бензиновий</option>
                                            <option
                                                value="diesel">Дизельний</option>
                                            <option
                                                value="hybrid">Гібрид</option>
                                            <option
                                                value="electric">Електро</option>
                                        </select>
                                        <span className="ti-angle-down"/>
                                    </div>
                                </span>
                        </label>
                        <label>Об'єм двигуна(см.куб)/Ємність бат.(кВт.год)
                            <br/>
                            <div
                                className="wpcf7-form-control-wrap calculator_number_input_wrapper">
                                <input type="number"
                                       min="0"
                                       name="engine_power"
                                       value={params.engine_power || ''}
                                       onChange={handleChange}
                                       className="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                       aria-required="true"
                                       aria-invalid="false"/>
                            </div>
                        </label>
                        <div className="last calculate_btn_wrapper">
                            <input id="calculate_btn" type="submit"
                                   className="button" value="Порахувати"
                                   onClick={handleCalculate}/>
                        </div>
                    </div>
                </div>
                <div className="calculator_results">
                    {!results && <div className="info_about_calculator">
                        <h3>Потрібна допомога з підбором авто?</h3>
                        <br/>
                        Напишіть або зателефонуйте і наші менеджери допоможуть
                        підібрати оптимальний варіант.
                        <br/>
                        <br/>
                        <button className="button contact_us_button"
                                onClick={() => setShowContactUsModal(true)}  style={{marginRight:10, marginBottom: 10}}>
                            Зв'язатись з нами
                        </button>
                        <button className="button contact_me_button"
                                onClick={() => setShowCallbackModal(true)}>
                            Зателефонуйте мені
                        </button>
                    </div>
                    }
                    {results &&
                        <>
                            <div className="calculator_results_table">
                                <h3>Доставка</h3>
                                <hr/>
                                <div className="car_results_table_row">
                                    <div className="column_title">Доставка морем
                                    </div>
                                    <div
                                        className="column_Value">{results.seaDelivery ||
                                        0}$
                                    </div>
                                </div>
                                <div className="car_results_table_row">
                                    <div className="column_title">Вартість послуг
                                        Global Auto Logistic
                                    </div>
                                    <div
                                        className="column_Value">{results.companyServices ||
                                        0}$
                                    </div>
                                </div>
                                <div className="car_results_table_row">
                                    <div className="column_title">Страхівка</div>
                                    <div
                                        className="column_Value">{results.insurance ||
                                        0}$
                                    </div>
                                </div>
                                <div className="car_results_table_row">
                                    <div className="column_title">Сторедж в США
                                    </div>
                                    <div
                                        className="column_Value">{results.storageUSA ||
                                        0}$
                                    </div>
                                </div>
                                <div className="car_results_table_row"
                                     style={{paddingBottom: 5}}>
                                    <div className="column_title">Експедиція
                                        (вивантаження, розпломбування...)
                                    </div>
                                    <div
                                        className="column_Value">{results.expedition ||
                                        0}$
                                    </div>
                                </div>
                                <br/>
                                <h3>Витрати після прибуття в Україну</h3>
                                <hr/>
                                <div className="car_results_table_row"
                                     style={{paddingTop: 5}}>
                                    <div className="column_title">Брокерські
                                        послуги
                                    </div>
                                    <div className="column_Value">{results.broker ||
                                        0}$
                                    </div>
                                </div>
                                <div className="car_results_table_row">
                                    <div className="column_title">Погрузка
                                    </div>
                                    <div
                                        className="column_Value">{results.loading ||
                                        0}$
                                    </div>
                                </div>
                                <div className="car_results_table_row">
                                    <div className="column_title">Ввізне мито</div>
                                    <div className="column_Value">{results.toll ||
                                        0}$
                                    </div>
                                </div>

                                <div className="car_results_table_row">
                                    <div className="column_title">Акциз</div>
                                    <div className="column_Value">{results.excise ||
                                        0}$
                                    </div>
                                </div>
                                <div className="car_results_table_row">
                                    <div className="column_title">ПДВ</div>
                                    <div className="column_Value">{results.pdv ||
                                        0}$
                                    </div>
                                </div>
                                <div className="car_results_table_row">
                                    <div className="column_title">Доставка до
                                        Львова
                                    </div>
                                    <div
                                        className="column_Value">{results.deliveryToLviv ||
                                        0}$
                                    </div>
                                </div>
                            </div>
                        </>}
                    <br/>
                    {results && params.price && params.engine_type &&
                        <>
                            <div className="calculator_total_results_row">
                                <span>Загальна вартість: </span>
                                <span
                                    className="total_sum">{results.totalSum}$</span>
                            </div>
                            <br/>
                            <button className="button contact_us_button"
                                    onClick={() => setShowContactUsModal(true)}  style={{marginRight:10, marginBottom: 10}}>
                                Зв'язатись з нами
                            </button>
                            <button className="button contact_me_button"
                                    onClick={() => setShowCallbackModal(true)}>
                                Зателефонуйте мені
                            </button>
                            <br/>
                            <br/>
                            <span style={{fontSize: 12}}>
                            * Дані результати є орієнтовними, за детальнішим прорахунком звертайтесь до наших мереджерів.
                        </span>
                        </>}
                </div>
            </div>
        </div>
    </>

export const Calculator = compose(
    withLayout,
    withState('showContactUsModal', 'setShowContactUsModal', false),
    withState('showCallbackModal', 'setShowCallbackModal', false),
    withState('params', 'setParams', {
        type: VEHICLE_TYPE.auto,
        engine_type: ENGINE_TYPE.petrol,
        year: years[0],
    }),
    withState('results', 'setResults', null),
    withHandlers({
            handleCalculate: ({params, setResults}) => async () => {
                const {price, year, type, engine_power: enginePower, engine_type: engineType} = params
                const delivery = calculateDelivery(parseInt(price), type,
                    parseInt(year), engineType, parseInt(enginePower))
                setResults(delivery)
            },
            handleChange: ({setParams, params}) => (event) => {
                let {name, value} = event.target
                if (event.target.type === 'number') {
                    value = value.replace(/[^0-9]/g, '')
                }
                setParams({...params, [name]: value})
            },
        },
    ),
)(CalculatorComponent)

