import React from 'react';
import {compose} from 'recompose';
import {withLayout} from "../../../components/layout";

const TrackContainerComponent = () =>
    <>
        <div id="page_caption" className="withtopbar no_picture">
            <div className="page_title_wrapper">
                <div className="page_title_inner">
                    <h1 className="withtopbar">Відстежити контейнер</h1>
                </div>
            </div>
        </div>

        <div id="page_content_wrapper" className="hasbg withtopbar container_tracking">
            <div id="container_tracking_wrapper">
                <iframe scrolling="no"  title="container tracking" src="https://www.cargoloop.com/Tracking.aspx" width="100%" height="600"/>
            </div>
        </div>
    </>;

export const TrackContainer = compose(
    withLayout,
)(TrackContainerComponent);

