export const formatQuery = (query) => {
    let {page = 1, pageSize = 20, orderBy, orderDirection, filters} = query;

    return {
        page,
        limit: pageSize,
        filters: JSON.stringify(filters),
        ...(orderBy && {orderBy}),
        ...(orderBy && {orderDirection: orderDirection ? orderDirection.toUpperCase() : 'ASC'}),
    };
};