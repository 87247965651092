import React from 'react';
import {compose, lifecycle, withHandlers, withState} from 'recompose';
import {withLayout} from '../../../components/layout';
import {getCarBrands, getCars} from '../../../api/client';
import {Loader} from '../../../components/loader';
import {formatQuery} from '../../../helpers/query';
import {Link} from 'react-router-dom';

const years = Array.from({length: new Date().getFullYear() - 1995 + 1},
    (_, index) => 1995 + index);

const CarsComponent = ({brands, models, cars, isLoading, filters, handleSearch, handleChange, meta, handlePageChange, history, location}) =>
    <>
      <div id="page_caption" className="hasbg withtopbar cars_page_component"
           style={{
             backgroundImage: location.pathname.includes(
                 '/cars-on-way')
                 ?'url(/upload/custom/cars-page-3.jpeg)'
                 :'url(/upload/custom/cars_page2.jpeg)',
             height: 600,
             marginTop: 44,
           }}>
        <div className="page_title_wrapper">
          <div className="page_title_inner">
            <div className="page_title_content">
              <h1 className="withtopbar">{location.pathname.includes(
                  '/cars-on-way')
                  ? 'Автомобілі в дорозі'
                  : 'Автомобілі під замовлення'}</h1>
            </div>
          </div>
        </div>
      </div>
      <div id="page_content_wrapper" className="hasbg withtopbar ">
        <div className="sidebar_content cars_info">
          <div className="cars_input_block">
            <h4>Параметри</h4>
            {isLoading &&
            <Loader style={{marginLeft: '50%', paddingTop: 15}}/>}
            {!isLoading &&
            <div className="car_info_table" style={{paddingTop: 15}}>
              <label> Модель
                <br/>
                <span className="wpcf7-form-control-wrap">
							   <div
                     className="cars_select_input_wrapper themeborder">
								   	<select id="brand" name="brand"
                            onChange={handleChange}
                            value={filters.brand}>
								   				<option
                              value="">Всі марки авто</option>
                      {brands && brands.map(brand =>
                          <option key={brand}
                                  value={brand}>{brand}</option>,
                      )}
								   			</select>
								<span className="ti-angle-down"/>
								</div>
							</span>
              </label>
              <label> Марка
                <br/>
                <span className="wpcf7-form-control-wrap">
							   <div
                     className="cars_select_input_wrapper themeborder">
								   <select id="model" name="model"
                           onChange={handleChange}
                           value={filters.model}>
									   <option value="">Всі моделі авто</option>
                     {models &&
                     models.map(model =>
                         <option key={model}
                                 value={model}>{model}</option>,
                     )}
								</select>
								<span className="ti-angle-down"/>
								</div>
							</span>
              </label>
              <label> Рік випуску
                <br/>
                <span className="wpcf7-form-control-wrap">
							   <div className="cars_select_input_wrapper themeborder">
								  <select name="yearStart"
                          value={filters.yearStart}
                          onChange={handleChange}>
								   				<option value="">Від</option>
                    {years.map(year => <option value={year}
                                               key={year}>{year}</option>)}
								   			</select>
								<span className="ti-angle-down"/>
								</div>
                <div className="cars_select_input_wrapper themeborder"
                     style={{paddingTop: 5}}>
                     <select name="yearEnd"
                             value={filters.yearEnd}
                             onChange={handleChange}>
								   				<option value="">До</option>
                       {years.map(year => <option value={year}
                                                  key={year}>{year}</option>)}
								   			</select>
								<span className="ti-angle-down"/>
								</div>
							</span>
              </label>
              <label> Тип двигуна
                <br/>
                <span className="wpcf7-form-control-wrap">
							   <div
                     className="cars_select_input_wrapper themeborder">
                  <select name="engineType"
                          onChange={handleChange}
                          value={filters.engineType}>
                      <option value="">Всі типи двигунів</option>
                      <option value="petrol">Бензиновий</option>
                      <option value="diesel">Дизельний</option>
                      <option value="hybrid">Гібрид</option>
                      <option value="electric">Електро</option>
                  </select>
								<span className="ti-angle-down"/>
								</div>
							</span>
              </label>
              <label> Тип трансмісії
                <br/>
                <span className="wpcf7-form-control-wrap">
							   <div
                     className="cars_select_input_wrapper themeborder">
                  <select name="transmission"
                          onChange={handleChange}
                          value={filters.transmission}>
                      <option value="">Всі типи трансмісій</option>
                      <option value="automatic">Автоматична</option>
                      <option value="manual">Механічна</option>
                  </select>
								<span className="ti-angle-down"/>
								</div>
							</span>
              </label>

              <div className="last calculate_btn_wrapper">
                <input id="car_search_btn" type="submit"
                       className="button" value="Пошук"
                       onClick={handleSearch}/>
              </div>
            </div>}
          </div>
          <div className="cars_results">
            <div className="standard_wrapper">
              <div id="portfolio_filter_wrapper"
                   className="gallery classic three_cols portfolio-content section content clearfix"
                   data-columns="3">
                {cars && cars.map((car, index) =>
                    <div
                        className="element grid classic4_cols animated2"
                        key={index}>
                      <Link to={`/cars/${car.id}`} className="car_card_link">
                      <div
                          className="one_fourth gallery4 classic static filterable portfolio_type themeborder car_card">
                        <img src={car.mainImageUrl}
                             alt={car.brand}/>
                        <div
                            className="portfolio_info_wrapper">
                          <div
                              className="car_attribute_wrapper">
                            <h5>{car.brand}</h5>
                            <h5>{car.model}</h5>
                            <br/>
                            <div
                                className="car_attribute_price_day four_cols">
																	<span
                                      className="single_car_currency">$</span>
                              <span
                                  className="single_car_price">{car.priceUSD}</span>
                            </div>
                            <br className="clear"/>
                          </div>
                          <div
                              className="car_attribute_year">
																<span
                                    className="">{car.year}</span>
                          </div>
                        </div>
                      </div>
                      </Link>
                    </div>,
                )}
                {meta.totalItems === 0 &&
                <span>Автомобілів не знайдено</span>}

              </div>
              <br className="clear"/>
              {meta.totalItems > 0 && <>
                <div className="pagination">
                  {[...Array(meta.totalPages)].map(
                      (item, index) => {
                        const pageNumber = index + 1;

                        return meta.currentPage ===
                        pageNumber
                            ? <span key={pageNumber}
                                    className="current">{pageNumber}</span>
                            : <span key={pageNumber}
                                    className="not_active_page"
                                    onClick={() => handlePageChange(
                                        pageNumber)}>
						                                        {pageNumber}
						                                    </span>;
                      },
                  )}

                </div>
                <div className="pagination_detail">
                  Сторінка {meta.currentPage} з {meta.totalPages}
                </div>
              </>}
            </div>
          </div>
        </div>
      </div>
    </>;

export const Cars = compose(
    withLayout,
    withState('showContactUsModal', 'setShowContactUsModal', false),
    withState('filters', 'setFilters', {}),
    withState('page', 'setPage', 1),
    withState('isLoading', 'setIsLoading', false),
    withState('carBrandsWithModels', 'setCarBrandsWithModels', []),
    withState('models', 'setModels', []),
    withState('brands', 'setBrands', []),
    withState('cars', 'setCars', []),
    withState('meta', 'setMeta', {}),
    withHandlers({
      handleChange: ({setFilters, filters, setModels, carBrandsWithModels}) => ({target: {name, value}}) => {
        if (name === 'brand') {
          setModels(carBrandsWithModels[value]);
        }
        setFilters({...filters, [name]: value});
      },
      handleSearch: ({setCars, setIsLoading, filters, setMeta, page, location}) => async (e) => {
        setIsLoading(true);
        const isOnWay = location.pathname.includes('/cars-on-way');
        const {items, meta} = await getCars(
            formatQuery({filters:{...filters, isOnWay}, page, orderBy: 'brand', pageSize: 21}));
        setCars(items);
        setMeta(meta);
        setIsLoading(false);
      },
    }),
    lifecycle({
      async componentDidMount() {
        const {setBrands, setCarBrandsWithModels, handleSearch} = this.props;
        const carBrandsWithModelsData = await getCarBrands();
        setBrands(Object.keys(carBrandsWithModelsData));
        setCarBrandsWithModels(carBrandsWithModelsData);
        handleSearch();
      },
    }),
    withHandlers({
      handlePageChange: ({handleSearch, setPage}) => async (page) => {
        await setPage(page);
        handleSearch();
      },
    }),
)(CarsComponent);
