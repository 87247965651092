import {get, post} from './utils';

export const trackCar = async vin => get(`/track?vin=${vin}`);

export const getFaqs = async () => get(`/faqs`);

export const getCarBrands = async () => get(`/car-brands`);
export const getCars = async (params) => get(`/cars`, params);
export const getCar = async (id) => get(`/cars/${id}`, {});

export const leaveCallbackRequest = async (params) => post(`/callback-request`, params);