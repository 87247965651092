import React from 'react';
import {compose, lifecycle, withHandlers, withState} from 'recompose';
import {withLayout} from '../../../components/layout';
import {ContactUsModal} from '../../../components/contact-us-modal';
import {Loader} from '../../../components/loader';
import ImageViewer from 'react-simple-image-viewer';
import {getCar} from '../../../api/client';
import {getEngineTypeLabel} from '../../../helpers/utils.helper';
import {CallbackModal} from '../../../components/callback-modal';

const CarViewComponent = ({showContactUsModal, setShowContactUsModal, showCallbackModal, setShowCallbackModal, car, isLoading, isViewerOpen, currentImage, closeImageViewer, openImageViewer}) =>
    <>
      <ContactUsModal show={showContactUsModal}
                      onClose={() => setShowContactUsModal(false)}/>

                      <CallbackModal show={showCallbackModal}
                      onClose={() => setShowCallbackModal(false)}/>
      <div id="page_caption" className="hasbg withtopbar"
           style={{
             backgroundImage: car.galleryImagesUrls
                 ? `url('${car.galleryImagesUrls[0]}')`
                 : '',
             height: '60vh',
             marginTop: 40,
           }}
      >
        <div className="single_car_header_button">
          <div className="standard_wrapper">
            <span id="single_car_gallery_open" className="button"
                  onClick={() => openImageViewer(0)}>
              <span className="ti-camera"/>Всі фото</span>
          </div>
        </div>
      </div>
      {isViewerOpen && (
          <ImageViewer
              src={car.galleryImagesUrls ? car.galleryImagesUrls : []}
              currentIndex={currentImage}
              disableScroll={false}
              closeOnClickOutside={true}
              onClose={closeImageViewer}
          />
      )}

      <div id="page_content_wrapper"
           className="hasbg withtopbar cars_info_block">
        {isLoading && <Loader/>}
        {!isLoading && <>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div style={{marginRight: 20}}>
              <h1>{car.brand} {car.model} {car.year}</h1>
            </div>
            <div>
              <h1>{car.priceUSD && car.priceUSD.toLocaleString()}$</h1>
            </div>
          </div>
          <div
              className="car_attributes single_car_attribute_wrapper themeborder">
            <div className="one_fifth">
              <div className="car_attribute_icon ti-panel"
                   style={{marginLeft: 5}}/>
              <div className="car_attribute_content">
                {car.transmission === 'automatic' ? 'Автоматична' : 'Механічна'}
              </div>
            </div>
            <div className="one_fifth">
              <svg width="48" height="40"
                   style={{marginTop: -5, marginLeft: -5}}>
                <image xlinkHref="/upload/custom/icons/engine.svg" width="48"
                       height="40"/>
              </svg>
              <div className="car_attribute_content" style={{marginTop: -10}}>
                {getEngineTypeLabel(car.engineType)}
              </div>
            </div>
            <div className="one_fifth">
              <svg width="48" height="34" style={{marginLeft: -5}}>
                <image xlinkHref="/upload/custom/icons/measure.svg" width="48"
                       height="34"/>
              </svg>
              <div className="car_attribute_content" style={{marginTop: -10}}>
                {car.engineSize} {car.engineType === 'electric'
                  ? 'кВт.год'
                  : 'см.куб'}
              </div>
            </div>
            <div className="one_fifth">
              <svg width="48" height="34"
                   style={{marginTop: 0, marginLeft: -5}}>
                <image xlinkHref="/upload/custom/icons/radar.svg" width="48"
                       height="34"/>
              </svg>
              <div className="car_attribute_content" style={{marginTop: -10}}>
                {Number(car.mileage).toLocaleString()} км
              </div>
            </div>
          </div>
          {car.description &&
          <div className="single_car_content" style={{paddingTop: 10}}>
            <h4 className="p1">Опис</h4>
            <p className="p2">{car.description}</p>
          </div>}
          <button className="button contact_us_button"
                  style={{marginTop: 20, marginBottom: 20}}
                  onClick={() => setShowContactUsModal(true)}>
            Цікавить автомобіль
          </button>
          <button className="button contact_me_button"
                  onClick={() => setShowCallbackModal(true)}>
            Зателефонуйте мені
          </button>
        </>}
      </div>
    </>;

export const CarView = compose(
    withLayout,
    withState('isLoading', 'setIsLoading', null),
    withState('showContactUsModal', 'setShowContactUsModal', false),
    withState('showCallbackModal', 'setShowCallbackModal', false),
    withState('car', 'setCar', []),
    withState('currentImage', 'setCurrentImage', 0),
    withState('isViewerOpen', 'setIsViewerOpen', false),
    lifecycle({
      async componentDidMount() {
        const {match: {params: {id}}, setCar, setIsLoading} = this.props;
        let car = {};
        if (id) {
          setIsLoading(true);
          car = await getCar(id);
          setCar(car);
          setIsLoading(false);
          window.scrollTo(0, 0);
        }
      },
    }),
    withHandlers({
      openImageViewer: ({setCurrentImage, setIsViewerOpen}) => (index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
      },
      closeImageViewer: ({setCurrentImage, setIsViewerOpen}) => () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
      },
    }),
)(CarViewComponent);
