import React from 'react'

export const Modal = ({ show, onClose, children, title, ...props }) => <>
	{show &&
	<div id="modal-dialog" {...props}>
		<div className="modal-container">
			<h3>{title}</h3>
			<span className="modal-close-x" aria-hidden="true" onClick={onClose}
				  style={{ paddingRight: 10 }}>✕</span>
			<hr/>
			{children}

		</div>
	</div>
	}
</>