import { MainPage } from '../pages/client/main-page'
import { Cars } from '../pages/client/cars'
import { FAQs } from '../pages/client/information/faqs.jsx'
import { OurTeam } from '../pages/client/information/our-team'
import { TrackCar } from '../pages/client/tracking/track-car'
import { TrackContainer } from '../pages/client/tracking/track-container'
import { Calculator } from '../pages/client/calculator/calculator'
import { Contacts } from '../pages/client/information/contacts'
import { Reviews } from '../pages/client/information/reviews'
import {CarView} from '../pages/client/cars/car-view';

export const routes = [
	{ to: '/', component: MainPage },
	{ to: '/track-container', component: TrackContainer },
	{ to: '/track-car-by-vin', component: TrackCar },
	{ to: '/cars/:id', component: CarView },
	{ to: '/cars', component: Cars },
	{ to: '/cars-on-way', component: Cars },
	{ to: '/faqs', component: FAQs },
	{ to: '/our-team', component: OurTeam },
	{ to: '/contacts', component: Contacts },
	{ to: '/reviews', component: Reviews },
	{ to: '/calculator', component: Calculator },
]
