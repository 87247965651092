import React from 'react';
import {compose} from 'recompose';
import {withLayout} from "../../../components/layout";
import {withRouter} from "react-router-dom";

const MainPageComponent = ({history}) =>
    <div className="ppb_wrapper">
        <div className="one fullwidth ">
            <div id="rev_slider_1_1_wrapper" className="rev_slider_wrapper fullscreen-container"
                 data-source="gallery"
                 style={{background: 'transparent', padding: 0}}>
                <div id="rev_slider_1_1" className="rev_slider fullscreenbanner" style={{display: 'none'}}
                     data-version="5.4.5.1">
                    <ul>
                        <li data-index="rs-1" data-transition="fade" data-slotamount="default"
                            data-hideafterloop="0"
                            data-hideslideonmobile="off" data-easein="default" data-easeout="default"
                            data-masterspeed="300"
                            data-thumb="delivery"
                            data-rotate="0" data-saveperformance="off" data-mediafilter="xpro2" data-title="Slide"
                            data-param1="" data-param2="" data-param3="" data-param4="" data-param5=""
                            data-param6=""
                            data-param7="" data-param8="" data-param9="" data-param10="" data-description="">
                            <video id="main_page_video" autoPlay loop muted playsInline >
                                <source
                                    src="/upload/videos/container-ship-v4.mp4"
                                    type="video/mp4"/>
                            </video>
                            <div className="tp-caption tp-resizeme" id="slide-1-layer-1" data-x="center"
                                 data-hoffset="" data-y="center" data-voffset="10" data-width="['auto']"
                                 data-height="['auto']" data-type="text" data-responsive_offset="on"
                                 data-frames='[{"delay":1000,"speed":800,"frame":"0","from":"z:0;rX:0;rY:0;rZ:0;sX:0.8;sY:0.8;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power4.easeOut"},{"delay":"wait","speed":500,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                 data-textalign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]"
                                 data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                 data-paddingleft="[0,0,0,0]"
                                 style={{
                                     "zIndex": "5",
                                     "whiteSpace": "nowrap",
                                     "fontSize": "60px",
                                     "lineHeight": "22px",
                                     "fontWeight": "600",
                                     "color": "rgba(255,255,255,1)",
                                     "letterSpacing": "",
                                     "fontFamily": "Poppins",
                                     "paddingBottom":"50px"
                                 }}>
                                Auto Logistic
                            </div>
                            <div className="tp-caption tp-resizeme" id="slide-1-layer-2" data-x="center" data-hoffset=""
                                 data-y="center" data-voffset="-80" data-width="['auto']" data-height="['auto']"
                                 data-type="text" data-responsive_offset="on"
                                 data-frames='[{"delay":1000,"speed":800,"frame":"0","from":"z:0;rX:0;rY:0;rZ:0;sX:0.8;sY:0.8;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power4.easeOut"},{"delay":"wait","speed":500,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                 data-textalign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]"
                                 data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                 data-paddingleft="[0,0,0,0]"
                                 style={{
                                     "zIndex": "6",
                                     "whiteSpace": "nowrap",
                                     "fontSize": "160px",
                                     "lineHeight": "22px",
                                     "fontWeight": "700",
                                     "color": "rgba(255,255,255,1)",
                                     "letterSpacing": "",
                                     "fontFamily": "Poppins"
                                 }}>GLOBAL
                            </div>
                            <div className="tp-caption tp-resizeme" id="slide-1-layer-3" data-x="center" data-hoffset=""
                                 data-y="center" data-voffset="60" data-width="['auto']" data-height="['auto']"
                                 data-visibility="['on','on','on','off']" data-type="text" data-responsive_offset="on"
                                 data-frames='[{"delay":1000,"speed":800,"frame":"0","from":"z:0;rX:0;rY:0;rZ:0;sX:0.8;sY:0.8;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power4.easeOut"},{"delay":"wait","speed":500,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                 data-textalign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]"
                                 data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                 data-paddingleft="[0,0,0,0]"
                                 style={{
                                     "zIndex": "7",
                                     "whiteSpace": "nowrap",
                                     "fontSize": "18px",
                                     "lineHeight": "22px",
                                     "fontWeight": "400",
                                     "color": "rgba(201,201,201,1)",
                                     "letterSpacing": "",
                                     // "fontFamily": "Poppins"
                                 }}>
                                Пошук та доставка автомобілів
                            </div>

                            <a className="tp-caption rev-btn rev-withicon slider_button"
                               href="/calculator"
                               onClick={() => history.push('/calculator')}
                               onTouchEnd={(e) => history.push('/calculator')}
                               target="_self" id="slide-1-layer-5" data-x="center" data-hoffset="" data-y="center"
                               data-voffset="141" data-width="['auto']" data-height="['auto']" data-type="button"
                               data-actions='' data-responsive_offset="on" data-responsive="off"
                               data-frames='[{"delay":1000,"speed":800,"frame":"0","from":"z:0;rX:0;rY:0;rZ:0;sX:0.8;sY:0.8;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power4.easeOut"},{"delay":"wait","speed":500,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"0","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(255,255,255,1);bg:rgba(88,86,214,1);bc:rgba(30,198,182,1);bs:solid;bw:0 0 0 0;"}]'
                               data-textalign="['inherit','inherit','inherit','inherit']"
                               data-paddingtop="[12,12,12,12]" data-paddingright="[35,35,35,35]"
                               data-paddingbottom="[12,12,12,12]" data-paddingleft="[35,35,35,35]"
                               style={{
                                   "zIndex": "8",
                                   "whiteSpace": "nowrap",
                                   "fontSize": "15px",
                                   "lineHeight": "17px",
                                   "fontWeight": "500",
                                   "color": "rgba(255,255,255,1)",
                                   "letterSpacing": "",
                                   // "fontFamily": "Poppins",
                                   "backgroundColor": "#3450ff",
                                   "borderColor": " #3450ff",
                                   "borderRadius": "30px 30px 30px 30px",
                                   "outline": "none",
                                   "boxShadow": "none",
                                   "boxSizing": "border-box",
                                   "MozBoxSizing": "border-box",
                                   "WebkitBoxSizing": "border-box",
                                   "cursor": "pointer",
                                   "textDecoration": "none"
                               }}>
                                Прорахувати вартість<i className="fa-icon-chevron-right"/>
                            </a>
                        </li>
                        <li data-index="rs-2" data-transition="fade" data-slotamount="default"
                            data-hideafterloop="0"
                            data-hideslideonmobile="off" data-easein="default" data-easeout="default"
                            data-masterspeed="300" data-thumb="upload/shutterstock_74418757-100x50.jpg"
                            data-rotate="0"
                            data-saveperformance="off" data-title="Slide" data-param1="" data-param2=""
                            data-param3=""
                            data-param4="" data-param5="" data-param6="" data-param7="" data-param8=""
                            data-param9=""
                            data-param10="" data-description="">
                            <img src="/upload/custom/auto-sales.webp"
                                 // src="https://www.thesun.co.uk/wp-content/uploads/2023/01/dealer-new-cars-stock-colorful-283936186.jpg?strip=all&w=1960"
                                 alt="" title="shutterstock_74418757"
                                 width="1440" height="1248" data-bgposition="center center" data-bgfit="cover"
                                 data-bgrepeat="no-repeat" className="rev-slidebg" data-no-retina/>
                            <div className="tp-caption tp-resizeme" id="slide-2-layer-1" data-x="60" data-y="center"
                                 data-voffset="" data-width="['auto']" data-height="['auto']" data-type="text"
                                 data-responsive_offset="on"
                                 data-frames='[{"delay":1000,"speed":800,"frame":"0","from":"z:0;rX:0;rY:0;rZ:0;sX:0.8;sY:0.8;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power4.easeOut"},{"delay":"wait","speed":500,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                 data-textalign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]"
                                 data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                 data-paddingleft="[0,0,0,0]"
                                 style={{
                                     "zIndex": "5",
                                     "whiteSpace": "nowrap",
                                     "fontSize": "56px",
                                     "lineHeight": "48px",
                                     "fontWeight": "600",
                                     "color": "rgba(255,255,255,1)",
                                     "letterSpacing": "",
                                     "fontFamily": "Poppins",
                                 }}><span style={{fontSize:70, fontWeight:700}}>GLOBAL</span>
                                <br/>Auto Park
                            </div>

                            <div className="tp-caption tp-resizeme" id="slide-2-layer-3" data-x="60" data-y="center"
                                 data-voffset="80" data-width="['auto']" data-height="['auto']"
                                 data-visibility="['on','on','on','off']" data-type="text" data-responsive_offset="on"
                                 data-frames='[{"delay":1000,"speed":800,"frame":"0","from":"z:0;rX:0;rY:0;rZ:0;sX:0.8;sY:0.8;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power4.easeOut"},{"delay":"wait","speed":500,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                                 data-textalign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]"
                                 data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]"
                                 data-paddingleft="[0,0,0,0]"
                                 style={{
                                     "zIndex": "6",
                                     "whiteSpace": "nowrap",
                                     "fontSize": "18px",
                                     "lineHeight": "22px",
                                     "fontWeight": "400",
                                     "color": "rgba(255,255,255,1)",
                                     "letterSpacing": "",
                                 }}>
                                Авто під замовлення та в наявності
                            </div>
                            <a className="tp-caption rev-btn rev-withicon slider_button"
                               href="/cars"
                               onTouchEnd={(e) => history.push('/cars')}
                               onClick={(e) => history.push('/cars')}
                               id="slide-2-layer-5" data-x="60" data-y="center" data-voffset="170" data-width="['auto']"
                               data-height="['auto']" data-type="button" data-actions='' data-responsive_offset="on"
                               data-responsive="off"
                               data-frames='[{"delay":1000,"speed":800,"frame":"0","from":"z:0;rX:0;rY:0;rZ:0;sX:0.8;sY:0.8;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power4.easeOut"},{"delay":"wait","speed":500,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"0","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;","style":"c:rgba(255,255,255,1);bg:rgba(88,86,214,1);bc:rgba(30,198,182,1);bs:solid;bw:0 0 0 0;"}]'
                               data-textalign="['inherit','inherit','inherit','inherit']"
                               data-paddingtop="[12,12,12,12]" data-paddingright="[35,35,35,35]"
                               data-paddingbottom="[12,12,12,12]" data-paddingleft="[35,35,35,35]"
                               style={{
                                   "zIndex": "7",
                                   "whiteSpace": "nowrap",
                                   "fontSize": "15px",
                                   "lineHeight": "17px",
                                   "fontWeight": "500",
                                   "color": "rgba(255,255,255,1)",
                                   "letterSpacing": "",
                                   // "fontFamily": "Poppins",
                                   "backgroundColor": "rgba(4,219,192,1)",
                                   "borderColor": "rgba(4,219,192,1)",
                                   "borderRadius": "30px 30px 30px 30px",
                                   "outline": "none",
                                   "boxShadow": "none",
                                   "boxSizing": "border-box",
                                   "MozBoxSizing": "border-box",
                                   "WebkitBoxSizing": "border-box",
                                   "cursor": "pointer",
                                   "textDecoration": "none",
                               }}>Підібрати автомобіль<i className="fa-icon-chevron-right"/>
                            </a>
                        </li>
                    </ul>
                    <div className="tp-bannertimer tp-bottom" style={{visibility: 'hidden !important'}}/>
                </div>
            </div>
        </div>
    </div>;

export const MainPage = compose(
    withRouter,
    withLayout,
)(MainPageComponent);

