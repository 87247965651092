import React from 'react'
import { Modal } from './modal'

export const ContactUsModal = ({ show, onClose }) =>
	<Modal show={show}
		   onClose={onClose}
		   title="Зв'язок з нами">
		<div className="modal_content">
			<div className="modal_description" style={{ paddingBottom: 10 }}>
				Виберіть зручний для Вас спосіб.
			</div>

			<div className="modal_description">
				<div className="contact-buttons">
					<a href="viber://chat?number=+380969007676" target="_blank"
					   rel="noopener noreferrer"
					   className="contact-button viber-button">
						<span>Viber</span>
					</a>
					<a href="https://t.me/+380969007676" target="_blank"
					   rel="noopener noreferrer"
					   className="contact-button telegram-button">
						<span>Telegram</span>
					</a>
					<a href="tel:+380969007676"
					   className="contact-button call-button">
						<span>Зателефонувати</span>
					</a>
				</div>
			</div>
		</div>
	</Modal>