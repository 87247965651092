import React from 'react';
import InputMask from 'react-input-mask';
import {Modal} from './modal';
import {compose, withHandlers, withState} from 'recompose';
import {leaveCallbackRequest} from '../api/client';
import {Loader} from './loader';
import {withLayout} from './layout';

export const CallbackModalComponent = ({show, onClose, handleChange, isDisabled, leaveRequest, isSending, errorMessage}) =>
    <Modal show={show}
           onClose={onClose}
           title="Зателефонуйте мені">
      <div className="modal_content callback-modal">
        <div className="modal_description">
          <div style={{marginBottom: 10}}>
            <label htmlFor="phone">Введіть, будь ласка, ваш номер
              телефону.</label>
            <InputMask
                name="phone"
                mask="+38 (099) 999-99-99"
                maskChar="_"
                id="phone"
                type="tel"
                placeholder="+38 (___) ___-__-__"
                onChange={handleChange}
            />
            <br/>
            {errorMessage &&
            <label style={{color: 'red'}}>{errorMessage}</label>}
          </div>
          {isSending ?
              (<Loader style={{marginLeft: '5%'}}/>)
              : (<button className="button contact_me_button"
                         disabled={isDisabled}
                         onClick={leaveRequest}>
                <span>Відправити</span>
              </button>)
          }
        </div>
      </div>
    </Modal>;

export const CallbackModal = compose(
    withLayout,
    withState('item', 'setItem', {phone: ''}),
    withState('isDisabled', 'setIsDisabled', true),
    withState('isSending', 'setIsSending', false),
    withState('errorMessage', 'setErrorMessage', ''),
    withHandlers({
      handleChange: ({setItem, item, setIsDisabled, setErrorMessage}) => ({target: {name, value}}) => {
        setIsDisabled(value.length < 10 || value.includes('_'));
        setErrorMessage('');
        setItem(
            {...item, [name]: value});
      },
      leaveRequest: ({item, onClose, isDisabled, setIsSending, setErrorMessage}) => async () => {
        if (isDisabled) {
          return;
        }
        try {
          await leaveCallbackRequest(
              {phone: item.phone.replace(/[^0-9+]/g, '')});
          setIsSending(true);
          setTimeout(() => {
            onClose();
            setIsSending(false);
          }, 1500);
        } catch (e) {
          setErrorMessage('Шось пішло не так, можливо ви вже залишали заявку.');
        }
      },
    }),
)(CallbackModalComponent);