import React from 'react';
import {compose, lifecycle, withHandlers, withState} from 'recompose';
import {withLayout} from "../../../components/layout";
import ImageViewer from 'react-simple-image-viewer';
import {trackCar} from "../../../api/client";
import {Loader} from "../../../components/loader";

const TrackCarComponent = ({
                               openImageViewer, isViewerOpen, currentImage, closeImageViewer, vin, handleChange, item,
                               handleTrackCar, isLoading
                           }) =>
    <>
        <div id="page_caption" className="hasbg withtopbar"
             style={{backgroundImage: 'url(upload/custom/car-tracking.jpeg)', height: '25vh', marginTop: 40}}>
            <div className="page_title_wrapper">
                <div className="page_title_inner">
                    <div className="page_title_content">
                        <h1 className="withtopbar">Відстежити авто</h1>
                    </div>
                </div>
            </div>
        </div>

        <div id="page_content_wrapper" className="hasbg withtopbar">
            <div id="car_search_form" className="track_car_by_vin_form">
                {!isLoading && <>
                    <div className="vincode_input_wrapper">
                        <input type="text" name="vin" style={{width: '100%'}}
                               value={vin}
                               className="border"
                               aria-required="true"
                               placeholder={"Введіть VIN code"}
                               aria-invalid="false"
                               onChange={handleChange}/>
                    </div>
                    <div className="last vincode_track_button_wrapper">
                        <input id="car_search_btn" type="submit" className="button" value="Відстежити"
                               onClick={handleTrackCar}/>
                    </div>
                </>
                }
                {isLoading && <Loader/>}
            </div>
            {item && <div className="sidebar_content car_tracking_info">
                <h1>{item.vehicleMake} {item.vehicleModel}</h1>
                <div>
                    <div className="car_info_block">
                        <hr/>
                        <div className="car_info_table" style={{paddingTop: 5}}>
                            <div className="car_info_table_row">
                                <div className="column_title">Марка</div>
                                <div className="column_Value">{item.vehicleMake || '-'}</div>
                            </div>
                            <div className="car_info_table_row">
                                <div className="column_title">Модель</div>
                                <div className="column_Value">{item.vehicleModel || '-'}</div>
                            </div>
                            <div className="car_info_table_row">
                                <div className="column_title">Рік</div>
                                <div className="column_Value">{item.vehicleYear || '-'}</div>
                            </div>
                            <div className="car_info_table_row">
                                <div className="column_title">VIN</div>
                                <div className="column_Value">{item.vehicleVinNo || '-'}</div>
                            </div>
                            <div className="car_info_table_row" style={{paddingBottom: 5}}>
                                <div className="column_title">Документи</div>
                                <div className="column_Value">{item.documentTitle || '-'}</div>
                            </div>
                            <hr/>
                            <div className="car_info_table_row">
                                <div className="column_title">Дата замовлення</div>
                                <div className="column_Value">{item.orderEntryDate || '-'}</div>
                            </div>
                            <div className="car_info_table_row">
                                <div className="column_title">Дата отримання</div>
                                <div className="column_Value">{item.vehicleReceivedDate || '-'}</div>
                            </div>
                            <div className="car_info_table_row">
                                <div className="column_title">Відділення</div>
                                <div className="column_Value">{item.responsibleBranch || '-'}</div>
                            </div>
                            <div className="car_info_table_row" style={{paddingBottom: 5}}>
                                <div className="column_title">Перевізник</div>
                                <div className="column_Value">{item.shippingLineTitle || '-'}</div>
                            </div>
                            <hr/>
                            <div className="car_info_table_row" style={{paddingTop: 5}}>
                                <div className="column_title">Номер контейнера</div>
								<div className="column_Value">
									{item.containerNumber || '-'}
									{item.containerNumber &&
									<a className="map-button button"
									   href={`https://www.searates.com/container/tracking/?container=${item.containerNumber}`}
									   rel="noopener noreferrer"
									   target="_blank">
										<i className="fa fa-map-marker"/>
									</a>
									}
								</div>
                            </div>
                            <div className="car_info_table_row">
                                <div className="column_title">Кінцевий порт</div>
                                <div className="column_Value">{item.finalDestinationPortTitle || '-'}</div>
                            </div>
                            <div className="car_info_table_row">
                                <div className="column_title">Дата початку завантаження контейнера</div>
                                <div className="column_Value">{item.containerStartLoadDate || '-'}</div>
                            </div>
                            <div className="car_info_table_row">
                                <div className="column_title">Дата завершення завантаження контейнера</div>
                                <div className="column_Value">{item.containerFinishLoadDate || '-'}</div>
                            </div>
                            <div className="car_info_table_row">
                                <div className="column_title">Дата відправлення</div>
                                <div className="column_Value">{item.sailedDate || '-'}</div>
                            </div>
                            <div className="car_info_table_row">
                                <div className="column_title">Орієнтована дата прибуття</div>
                                <div className="column_Value">{item.ETA || '-'}</div>
                            </div>
                        </div>
                    </div>

                    <div className="car_tracking_info_images">
                        {item && item.images && item.images.map((src, index) => (
                            <div className="image_wrapper" key={index}>
                                <img src={src} alt=""
                                     onClick={() => openImageViewer(index)}
                                />
                            </div>))
                        }
                        {isViewerOpen && (
                            <ImageViewer
                                src={item.images}
                                currentIndex={currentImage}
                                disableScroll={false}
                                closeOnClickOutside={true}
                                onClose={closeImageViewer}
                            />
                        )}
                    </div>
                </div>
            </div>
            }
        </div>
    </>;

export const TrackCar = compose(
    withLayout,
    withState('isLoading', 'setIsLoading', null),
    withState('item', 'setItem', null),
    withState('vin', 'setVin', ''),
    withState('currentImage', 'setCurrentImage', 0),
    withState('isViewerOpen', 'setIsViewerOpen', false),
    lifecycle({
        async componentDidMount() {
            const vinQuery = new URLSearchParams(this.props.location.search).get('vin');
            const {setItem, setVin} = this.props;
            if (vinQuery) {
                const car = await trackCar(vinQuery);
                if (car.vehicleMake) {
                    setItem(car);
                    setVin(vinQuery);
                }
            }
        },
    }),
    withHandlers({
        openImageViewer: ({setCurrentImage, setIsViewerOpen}) => (index) => {
            setCurrentImage(index);
            setIsViewerOpen(true);
        },
        closeImageViewer: ({setCurrentImage, setIsViewerOpen}) => () => {
            setCurrentImage(0);
            setIsViewerOpen(false);
        },
        handleChange: ({setVin}) => ({target: {value}}) => setVin(value),
        handleTrackCar: ({history, vin, setItem, setIsLoading}) => async (e) => {
            setIsLoading(true);
            const car = await trackCar(vin);
            if (car.vehicleMake) {
                setItem(car);
                const params = new URLSearchParams();
                params.append('vin', vin);
                history.push({search: params.toString()});
            }
            setIsLoading(false);
        },
    })
)
(TrackCarComponent);

