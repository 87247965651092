import React from 'react';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import { compose, withState } from 'recompose';
import { routes } from './routes';

const App = () => <Content/>;

const ContentComponent = ({navigateTo}) => (
  <Switch>
    {
      routes.map(
        route => !route.hidden && (
          <Route
            key={route.to}
            exact={route.exact ? route.exact : true}
            path={route.to}
            render={props => <route.component {...props} navigateTo={navigateTo}/>}
          />
        ),
      )
    }
    <Redirect to="/"/>
  </Switch>
);

const Content = compose(
  withRouter,
)(ContentComponent);

export default compose(
  withState('loading', 'setLoading', false),
)(App);

