import React from 'react'
import {compose, lifecycle} from 'recompose'
import {withLayout} from '../../../components/layout'

const comments = [{
    date: '19 жовтня 2023',
    user: 'Артем Переверзев',
    avatar: '/upload/custom/comments/user-a.png',
    comment: 'Дуже задоволені своїм Chevrolet Bolt EV 2017 року від Global Auto Logistic! комплектація "Premier", працює - як годинник. Все - як було узгоджено і навіть краще. Особлива вдячність менеджеру, Ян Global Auto Logistic Бонецький! За дійсно порядне і дружнє відношення! І справжню фаховість. На всіх етапах все було зроблено якісно і зі знанням справи. Машина - супер 👍 і в домовленій ціні! Однозначно буду рекомендувати всім друзям! Успіхів і високих прибутків фірмі і персонально - Яну!',
    star: 5,
    photos: ['/upload/custom/comments/photos/c6.1.jpeg',],
}, {
    date: '18 жовтня 2023',
    user: 'arturweb',
    avatar: '/upload/custom/comments/user-aweb.png',
    comment: 'Замовляв BMW F06 2014 року з США, менеджер Дмитро. Головні критерії при подборі: ціла безпека, run & drive, мінімальне дтп, м-пакет та короткі сроки. В цілому все так і получилось і на декілька тисяч дешевше, ніж в Україні, машину отримав вже через 2 місяця, тому що спеціально шукали на сході США, щоб зекономити на сроках. Єдине, орієнтуйтесь на трохи більші витрати, ніж в розрахунку - так як є ще додаткова комісія при оплаті аукціону, стоянки, комісії при розмитненні і тд. Якщо маєте бажання поспілкуватися і розпитати за якісь нюанси - пишіть в тг arturweb.',
    star: 4,
    photos: ['/upload/custom/comments/photos/c11.1.jpg', '/upload/custom/comments/photos/c11.2.jpg', '/upload/custom/comments/photos/c11.3.jpg', '/upload/custom/comments/photos/c11.4.jpg', '/upload/custom/comments/photos/c11.5.jpg',],
}, {
    date: '15 жовтня 2023',
    user: 'Rostuk Madeya',
    avatar: '/upload/custom/comments/user-rm.png',
    comment: 'Роботою даної компанії задоволений на 200%, окрема подяка менеджеру Боднару Назару. Шукав авто для себе з жорсткими критеріями, щоб авто було з мінімальним пошкодженням, невеликим пробігом, і не за всі гроші світу. Авто підібрали дуже швидко. Менеджери на звʼязку 24/7. По термінах доставки я був попереджений що авто прибуде за два місяці, а по факту вийшло навіть швидше. Жодних прихованих платежів не було. Якщо буду шукати собі авто, обовʼязково буду звертатись до хлопців знову.',
    star: 5,
    photos: ['/upload/custom/comments/photos/c7.jpeg',],
}, {
    date: '8 жовтня 2023',
    user: 'Вячеслав Москвин',
    avatar: '/upload/custom/comments/user-vm.png',
    comment: 'Відмінній сервіс!!! Чутливий підхід до замовника, та якісна комунікація! Окрема, велика подяка менеджеру Костянтину! Дякую за чергову бібіку 😁',
    star: 5,
    photos: ['/upload/custom/comments/photos/c9.jpeg',],
}, {
    date: '4 жовтня 2023',
    user: 'Владимир Тимченко',
    avatar: '/upload/custom/comments/user-vt.png',
    comment: 'Дуже  задоволений співпрацею з компанією Global Auto Logistics. Авто швидко виграли, швидко привезли, все чітко працює як швейцарський годинник. Це була перша машина з США замовлена особисто і досвід здобув тільки  позитивний. Рекомендую менеджера Назара, відповідав навіть у вихідний і навіть уночі.  Рекомендую компанію до співпраці усім. Слава Україні!',
    star: 5,
    photos: ['/upload/custom/comments/photos/c12.jpeg',],
}, {
    date: '3 жовтня 2023',
    user: 'Юліана Рижик',
    avatar: '/upload/custom/comments/user-u.png',
    comment: 'Всім доброго дня. Компанію можу вам порадити. Замовляла машинку. Все прийшло вчасно. Велике дякую менеджеру Назару. Постійно на звʼязку, давав відповіді на всі питання. А питань в мене було багато так як я дівчина і мало що розумію) Постійно дзвонив і говорив де машина. Так, що звертайтесь до Назара і будете всі задоволені😉',
    star: 5,
    photos: ['/upload/custom/comments/photos/c8.jpeg',],
}, {
    date: '12 вересня 2023',
    user: 'Олександр Поперечний',
    avatar: '/upload/custom/comments/user-op.png',
    comment: 'Замовляв авто у цієї компанії, стан run&drive, працював зі мною менеджер Ян Бонецький, який був постійно на зв\'язку і всю інформацію по авто (де, що і як) мені скидував, по термінам 2 місяці, як і обгрворювали. По прибуттю в Україну була ситуація з поломкою двигуна, в якій ні компанія, ні я не винен був, але потрібно додаткові кошти на ремонт, на що я не розраховував. Але від мене не відвернулися і компенсували 700$ і 300$ на послуги компанії, коли буду наступного разу замовляти. Богдан сам зателефонував і ми вирішили цю ситуацію, уважуха! Дякую за розуміння та турботу і репутацію. Наступне авто обов\'язково замовлю у вас!)',
    star: 5,
    photos: ['/upload/custom/comments/photos/c1.jpeg', '/upload/custom/comments/photos/c1.1.jpeg', '/upload/custom/comments/photos/c1.2.jpeg', '/upload/custom/comments/photos/c1.4.jpeg',],
}, {
    date: '10 вересня 2023',
    user: 'Nazar Furdychka',
    avatar: '/upload/custom/comments/user-nf.png',
    comment: 'Довго вагався з вибором компанії, знайшов Global Auto Logistic по відгуках - і не помилився. Дуже дякую хлопцям за авто - Audi A4, а особливо менеджеру Олегу, який завжди був на зв\'язку та відповідав на будь-які питання. Машина дісталась до місця призначення за 2.5 місяці - все по плану. Самим аукціоном теж задоволений - Олег з командою допомогли підібрати хорошу машину з мінімальними пошкодженнями. Загалом все 🔥🔥🔥',
    star: 5,
    photos: ['/upload/custom/comments/photos/c2.1.jpeg', '/upload/custom/comments/photos/c2.2.jpeg',],
}, {
    date: '10 вересня 2023',
    user: 'Didlah _3097',
    avatar: '/upload/custom/comments/userD3097.png',
    comment: 'З дівчиною задумались придбати авто зі США, проаналізувавши компанії та відгуки про них вирішили звернутись саме до хлопців з global auto logistic, а саме до менеджера Святослава.Та не прогадали ,Святослав підібрав вигідний варіант з комплектацією такою як обговорювали та з мінімальним пошкодженням. Рекомендую цих хлопців ,якщо хочете спокійно та без підводних каменів придбати собі авто зі США. Окрема поядка менеджеру Святославу!',
    star: 5,
    photos: [],
}, {
    date: '16 липня 2023',
    user: 'FIZRUK',
    avatar: '/upload/custom/comments/user-f.png',
    comment: 'Всім доброго дня 👋. Хочу подякувати перш за все вашій компанії, і найкращому менеджеру Назару!!! Приганав АУДІ А4 🔥🔥🔥 Машинка була куплена майже цілою!Я дуже задоволений )) Було дуже багато порад, багато допомоги без якої я б несправився)Машина приїхала за 2,5 місяці ! Виграли її з першого разу😃Назар був на зв\'язку 24/7 , і завжди привітливий💪  Велике дякую за це🙃 Всім РЕКОМЕНДУЮ !!! Компанія найкраща ❤️',
    star: 5,
    photos: ['/upload/custom/comments/photos/c4.1.jpeg',],
}, {
    date: '23 червень 2023',
    user: 'Володимир Задерецький',
    avatar: '/upload/custom/comments/user-vz.png',
    comment: 'Дуже хороша компанія. Хлопці привітні, завжди на зв‘язку і допомагають з усіма питаннями. Замовляв Audi A4, з моменту покупки на аукціоні до моменту завершення ремонту 4,5 місяці. Ремонт дуже якісний і не за всі гроші світу) Максимально задоволений співпрацею і всім рекомендую!',
    star: 5,
    photos: ['/upload/custom/comments/photos/c5.1.jpeg', '/upload/custom/comments/photos/c5.2.jpeg',],
}, {
    date: '10 червня 2023',
    user: 'Павло Стельмах',
    avatar: '/upload/custom/comments/user-ps.png',
    comment: 'Замовляв В Global Auto Logistic автомобіль BMW F 10 зімною працював менеджер Назар дуже хороша людина він менш ніж за тиждень знайшов мені чудове авто з мінімальним пошкодженням я дуже задоволений роботою компанії все дуже злагоджено авто прибуло від моменту придбання за два місяця та сім дні я важаю що це дуже швидко також по платпжах так як Назар прорахуавав так все і вийшло навіть трішки дешевше. Рекомендую Global Auto Logistic',
    star: 5,
    photos: ['/upload/custom/comments/photos/c10.1.jpeg', '/upload/custom/comments/photos/c10.2.jpeg', '/upload/custom/comments/photos/c10.3.jpeg', '/upload/custom/comments/photos/c10.4.jpeg',],
}, {
    date: '5 червня 2023',
    user: 'Олександра Яшина',
    avatar: '/upload/custom/comments/user-oya.png',
    comment: 'Ми замовляли автомобіль у Global Auto Logistic і були приємно вражені якістю їх послуг. Однак, що ще важливіше, ми були повністю задоволені самим автомобілем. Він відповідав нашим очікуванням і виявився у відмінному стані.\n' +
        'Особливо хочемо відзначити нашого менеджера, Богдана, який працював з нами. Він був повністю відданий своїй справі і надавав професійні поради та рекомендації на кожному етапі процесу. Богдан був завжди доступним для спілкування і швидко відповідав на наші запитання. Також хочемо відзначити роботу Анатолія який займався веденням ремонту автомобіля.\n' +
        'Коли виникали деякі незначні проблеми, він докладав зусиль, щоб їх вирішити швидко та ефективно.\n' +
        'Ми рекомендуємо компанію Global Auto Logistic всім, хто шукає якісні автомобілі та надійного партнера в автомобільній сфері.',
    star: 5,
    photos: ['/upload/custom/comments/photos/c13.jpeg',],
}]
const ReviewsComponent = () => <>
    <div id="page_caption" className="withtopbar no_picture">
        <div className="page_title_wrapper">
            <div className="page_title_inner">
                <h1 className="withtopbar">Відгуки</h1>
            </div>
        </div>
    </div>
    <div id="page_content_wrapper" className="hasbg withtopbar">
        <div className="contact_us_page_content">
            <div className="comment-container">
                {comments.map((comment, ci) => (<div className="comment" key={ci}>
                        <div className="comment-header">
                            <img src={comment.avatar} alt="User Avatar"
                                 className="user-avatar"/>
                            <div
                                className="comment-author">{comment.user}</div>
                        </div>
                        <div className="comment-header">
                            <div className="comment-rating">
                                {Array.from({length: comment.star}).map(index => (
                                    <span key={index} className="star">&#9733;</span>
                                ))}
                            </div>
                            <div
                                className="comment-date">{comment.date}</div>
                        </div>
                        <div className="comment-body">
                            {comment.comment}
                            <br/>
                            {comment.photos.map((photo, pi) => (<img src={photo} alt="" key={pi}
                                                                     className="comment-image"/>))}
                        </div>
                    </div>
                ))}
            </div>
            <div className="comments-see-all">
                <a
                    href="https://www.google.com/maps/place/%D0%90%D0%B2%D1%82%D0%BE+%D0%B7+%D0%A1%D0%A8%D0%90+%D1%82%D0%B0+%D0%9A%D0%B0%D0%BD%D0%B0%D0%B4%D0%B8+Global+Auto+Logistic/@49.848995,24.0191328,15z/data=!4m18!1m9!3m8!1s0x473add0b48541f1f:0x33164d9238390d59!2z0JDQstGC0L4g0Lcg0KHQqNCQINGC0LAg0JrQsNC90LDQtNC4IEdsb2JhbCBBdXRvIExvZ2lzdGlj!8m2!3d49.8483115!4d24.0209668!9m1!1b1!16s%2Fg%2F11n00csjww!3m7!1s0x473add0b48541f1f:0x33164d9238390d59!8m2!3d49.8483115!4d24.0209668!9m1!1b1!16s%2Fg%2F11n00csjww?entry=ttu"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <div> Дивитись всі відгуки <i className="fa fa-external-link"/></div>
                </a>
            </div>
        </div>
    </div>

</>

export const Reviews = compose(withLayout, lifecycle({
    async componentDidMount() {
    },
}),)(ReviewsComponent)

