export const ENGINE_TYPE = {
    petrol: 'petrol',
    diesel: 'diesel',
    hybrid: 'hybrid',
    electric: 'electric',
}

export const VEHICLE_TYPE = {
    auto: 'auto',
    moto: 'moto',
}

const eurRate = 39;
const usdRate = 36;

// todo: get real rates
const EURToUSD = (eur) => {
    return eur * eurRate / usdRate;
}

const getExciseCoefficient = (year) => {
    const coefficient = new Date().getFullYear() - year - 1;
    if (coefficient < 1) {
        return 1;
    }
    if (coefficient > 15) {
        return 15;
    }

    return coefficient;
}

export const calculateTaxes = (price, type, year, engineType, enginePower) => {

    // Electric
    if (engineType === ENGINE_TYPE.electric) {
        return {
            toll: 0,
            excise: enginePower,
            pdv: 0,
        }
    }

    // Moto
    if (type === VEHICLE_TYPE.moto) {
        let motoExcise = 0;
        switch (true) {
            case enginePower < 500:
                motoExcise = enginePower * 0.062;
                break;
            case enginePower < 800:
                motoExcise = enginePower * 0.443;
                break;
            default :
                motoExcise = enginePower * 0.447;
                break;
        }

        motoExcise = EURToUSD(motoExcise);
        const toll = price * 0.1;

        return {
            toll: toll,
            excise: motoExcise,
            pdv: (price + toll + motoExcise) * 0.2,
        }
    }

    // Car
    let carExcise = 0;
    const coefficientExcise = getExciseCoefficient(year);

    if (engineType === ENGINE_TYPE.diesel) {
        carExcise = enginePower < 3500
            ? EURToUSD(75) * enginePower / 1000 * coefficientExcise
            : EURToUSD(150) * enginePower / 1000 * coefficientExcise;
    } else {
        carExcise = enginePower < 3000
            ? EURToUSD(50) * enginePower / 1000 * coefficientExcise
            : EURToUSD(100) * enginePower / 1000 * coefficientExcise;
    }
    const toll = (price +1600) * 0.1;

    return {
        toll: toll,
        excise: carExcise,
        pdv: (price + toll + carExcise + 1600) * 0.2,
    }
}

