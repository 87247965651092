import React from 'react';
import {compose, withState} from 'recompose';

const FAQQuestionComponent = ({showAnswer, setShowAnswer, question, answer}) => <>
    <div className="question_block">
        <div className="question_title" onClick={() => setShowAnswer(!showAnswer)}>
            <div className={'question_state_indicator ' + (showAnswer ? 'open' : 'closed')}/>
            <h6 className="title_text">{question}</h6>
        </div>
        {showAnswer && <div className="question_answer">
            {answer}
        </div>}
    </div>
</>;

export const FAQQuestion = compose(
    withState('showAnswer', 'setShowAnswer', false),
)(FAQQuestionComponent);