import React from 'react';
import {compose, lifecycle, withState} from 'recompose';
import {withLayout} from "../../../components/layout";
import {FAQQuestion} from "../../../components/faq-question";
import {getFaqs} from "../../../api/client";

const FAQsComponent = ({faqs}) =>
    <>
        <div id="page_caption" className="withtopbar no_picture">
            <div className="page_title_wrapper">
                <div className="page_title_inner">
                    <h1 className="withtopbar">Часті запитання</h1>
                </div>
            </div>
        </div>

        <div id="page_content_wrapper" className="hasbg withtopbar">
            {faqs &&
            Object.keys(faqs).map(groupKey =>
                <div className="question_group">
                    <div className="group_header_wrapper">
                        <h3>{groupKey}</h3>
                    </div>
                    {faqs[groupKey].map(faq =>
                        <FAQQuestion
                            question={faq.question}
                            answer={faq.answer}
                        />
                    )}
                </div>
                )}
        </div>
    </>;

export const FAQs = compose(
    withLayout,
    withState('faqs', 'setFaqs', []),
    lifecycle({
        async componentDidMount() {
            const {match: {params: {id}}, setFaqs} = this.props;
            const faqs = await getFaqs(id);
            setFaqs(faqs);
        },
    }),
)(FAQsComponent);

