import React from 'react';
import {compose, lifecycle, withHandlers} from 'recompose';

import {withRouter} from 'react-router-dom';

const LayoutComponent = React.memo(({children}) =>
    <>{children}</>
);

export const Layout = compose(
    withRouter,
    withHandlers({}),
    lifecycle({}),
)(LayoutComponent);

export const withLayout = Component => props => <Layout><Component {...props}/></Layout>;