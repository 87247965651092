import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';

import * as serviceWorker from './serviceWorker';
import './styles/index.scss';

import App from './App';
// eslint-disable-next-line import/no-unassigned-import
import 'whatwg-fetch';
// eslint-disable-next-line import/no-unassigned-import

ReactDOM.render(
    <Router>
        <App/>
    </Router>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
