import React from 'react';
import {compose} from 'recompose';
import {withLayout} from "../../../components/layout";

const OurTeamComponent = () =>
    <>
        <div id="page_caption" className="hasbg withtopbar about_us_header_image_block"
             style={{backgroundImage: 'url(upload/custom/about-us-2.jpg)', marginBottom: 0}}>

            {/*<div className="page_title_wrapper">*/}
            {/*    <div className="page_title_inner">*/}
            {/*        <div className="page_title_content">*/}
            {/*            <h1 className="withtopbar">Наша команда</h1>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>

        <div className="one withsmallpadding ppb_text our_team_stats_line"
             style={{
                 paddingTop: '0!important',
                 textAlign: 'center',
                 padding: '10px 0 10px 0',
                 color: '#ffffff !important'
             }}>
            <div className="standard_wrapper">
                <div className="page_content_wrapper">
                    <div className="inner">
                        <div style={{margin: 'auto', width: '100%', color: 'white'}}>
                            <div className="one_third" style={{marginTop: '2%'}}>
                                <span className="ti-car smile"
                                      style={{display: 'block', fontSize: 50, marginBottom: 20}}>&nbsp;</span>
                                <h4 style={{color: '#fff'}}>Доставлених авто 5000+</h4>
                            </div>
                            <div className="one_third" style={{marginTop: '2%'}}>
                                <span className="ti-face-smile smile"
                                      style={{display: 'block', fontSize: 50, marginBottom: 20}}>&nbsp;</span>
                                <h4 style={{color: '#fff'}}>Клієнтів 3000+ </h4>
                            </div>
                            <div className="one_third last" style={{marginTop: '2%'}}>
                                <span className="ti-heart smile"
                                      style={{display: 'block', fontSize: 50, marginBottom: 20}}>&nbsp;</span>
                                <h4 style={{color: '#fff'}}>Любимо свою справу :-)</h4>
                            </div>
                            <p>
                                <br className="clear"/>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="one">
            <div className="one_half_bg parallax"
                 style={{backgroundImage: 'url(upload/custom/about-us-1.jpg)', height: 550}}/>
            <div className="one_half_bg" style={{padding: 80}}>
                <h2 className="ppb_title">Про нас</h2>
                <div className="ppb_header_content">
                    <p>Компанія “Global Auto Logistic” співпрацює з IAAI, Copаrt , Manheim, Impact , Adesa.</p>

                    <p>Наша компанія здійснює перевезення авто по території США та України.
                        Весь процес супроводжується фотозвітами. Готуємо всі необхідні документи для експорту
                        автомобілів в Україну.
                        Всім клієнтам надаємо трекінг, щоб відстежувати рух вашого авто. Надаємо послуги по доставці
                        автомобілів, більш ніж 10 компаніям по Україні.</p>
                </div>
            </div>
            <br className="clear"/>
        </div>


        {/*<div className="one withsmallpadding ppb_team_column main_team_members"*/}
        {/*     style={{backgroundColor: '#000000', paddingBottom: 0, borderBottom: '1px solid #2e3131'}}>*/}
        {/*    <div className="page_content_wrapper page_main_content sidebar_content full_width fixed_column photoframe">*/}
        {/*        <div className="standard_wrapper">*/}
        {/*            <h3 className="sub_title"*/}
        {/*                style={{borderBottom: '1px solid #2e3131', color: 'white', marginBottom: 30}}>Наша команда</h3>*/}
        {/*            <div id="15722574382014118329" className="portfolio_filter_wrapper gallery five_cols"*/}
        {/*                 data-columns="5">*/}
        {/*                <div className="element grid photoframe fixed_columns classic5_cols animated1">*/}
        {/*                    <div className="one_fifth gallery5 classic filterable">*/}
        {/*                        <div className="post_img">*/}
        {/*                            <img className="team_pic" src="upload/pexels-photo-62365-500x500.jpeg" alt=""/>*/}
        {/*                            /!*<ul className="social_wrapper team">*!/*/}
        {/*                            /!*    <li><a title="Jessica Lee on Twitter" target="_blank" className="tooltip"*!/*/}
        {/*                            /!*           href="https://twitter.com/#"><i className="fa fa-twitter"/></a>*!/*/}
        {/*                            /!*    </li>*!/*/}
        {/*                            /!*    <li><a title="Jessica Lee on Facebook" target="_blank" className="tooltip"*!/*/}
        {/*                            /!*           href="https://facebook.com/#"><i className="fa fa-facebook"/></a>*!/*/}
        {/*                            /!*    </li>*!/*/}
        {/*                            /!*    <li><a title="Jessica Lee on Google+" target="_blank" className="tooltip"*!/*/}
        {/*                            /!*           href="#"><i className="fa fa-google-plus"/></a></li>*!/*/}
        {/*                            /!*    <li><a title="Jessica Lee on Linkedin" target="_blank" className="tooltip"*!/*/}
        {/*                            /!*           href="#"><i className="fa fa-linkedin"/></a></li>*!/*/}
        {/*                            /!*</ul>*!/*/}
        {/*                        </div>*/}
        {/*                        <div className="portfolio_info_wrapper center">*/}
        {/*                            <h4>Jessica Lee</h4>*/}
        {/*                            <div className="page_tagline">CEO</div>*/}
        {/*                        </div>*/}
        {/*                    </div>*/}
        {/*                </div>*/}
        {/*                <div className="element grid photoframe fixed_columns classic5_cols animated2">*/}
        {/*                    <div className="one_fifth gallery5 classic filterable">*/}
        {/*                        <div className="post_img">*/}
        {/*                            <img className="team_pic" src="upload/pexels-photo-57759-500x500.jpeg" alt=""/>*/}
        {/*                        </div>*/}
        {/*                        <div className="portfolio_info_wrapper center">*/}
        {/*                            <h4>John Bennett</h4>*/}
        {/*                            <div className="page_tagline">Asia Tour Expert</div>*/}
        {/*                        </div>*/}
        {/*                    </div>*/}
        {/*                </div>*/}
        {/*                <div className="element grid photoframe fixed_columns classic5_cols animated3">*/}
        {/*                    <div className="one_fifth gallery5 classic filterable">*/}
        {/*                        <div className="post_img">*/}
        {/*                            <img className="team_pic" src="upload/pexels-photo-176045-500x500.jpeg" alt=""/>*/}
        {/*                        </div>*/}
        {/*                        <div className="portfolio_info_wrapper center">*/}
        {/*                            <h4>Christina Hardy</h4>*/}
        {/*                            <div className="page_tagline">Marketing Manager</div>*/}
        {/*                        </div>*/}
        {/*                    </div>*/}
        {/*                </div>*/}
        {/*                <div className="element grid photoframe fixed_columns classic5_cols animated4">*/}
        {/*                    <div className="one_fifth gallery5 classic filterable">*/}
        {/*                        <div className="post_img">*/}
        {/*                            <img className="team_pic" src="upload/pexels-photo-167471-500x500.jpeg" alt=""/>*/}
        {/*                        </div>*/}
        {/*                        <div className="portfolio_info_wrapper center">*/}
        {/*                            <h4>Oliver Doe</h4>*/}
        {/*                            <div className="page_tagline">Europe Tour Expert</div>*/}
        {/*                        </div>*/}
        {/*                    </div>*/}
        {/*                </div>*/}
        {/*                <div className="element grid photoframe fixed_columns classic5_cols animated5">*/}
        {/*                    <div className="one_fifth gallery5 classic filterable">*/}
        {/*                        <div className="post_img">*/}
        {/*                            <img className="team_pic" src="upload/pexels-photo-48646-1-500x500.jpeg" alt=""/>*/}
        {/*                        </div>*/}
        {/*                        <div className="portfolio_info_wrapper center">*/}
        {/*                            <h4>Jane Bennett</h4>*/}
        {/*                            <div className="page_tagline">Customer Support</div>*/}
        {/*                        </div>*/}
        {/*                    </div>*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}
    </>;

export const OurTeam = compose(
    withLayout,
)(OurTeamComponent);

